import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Stack
} from '@mui/material';
import union from 'lodash/union';
import { AutocompleteElement, useForm } from 'react-hook-form-mui';
import { useWS } from '../../../contexts/WebSocketContext';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useGetMembersQuery } from '../../../services/user';

function InviteMemberDialog({ group, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data } = useGetMembersQuery();
  const { updateGroup } = useWS();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      members: []
    }
  });

  if (!group) return null;

  function getMembers() {
    if (!data) return [];

    const members = data
      .filter(item => !group.members.some(user => user._id === item._id))
      .map(item => ({
        id: item._id,
        label: `${item.firstName} ${item.lastName}`.trim()
      }));

    //* Filter out duplicate members by label.
    const uniqueMembers = members.reduce((acc, current) => {
      const isDuplicate = acc.find(item => item.label === current.label);
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, []);

    return uniqueMembers
      .filter( ( member ) => !group.members.some( user => user._id === member.id ) )
      .sort( ( a, b ) => a.label.localeCompare( b.label ) );
  }

  async function handleInviteMembers(formData) {
    try {
      const updatedMembers = union(
        group.members.map(m => m._id),
        formData.members
      );
      updateGroup({
        id: parseInt(group._id),
        name: group.name,
        description: group.description,
        members: updatedMembers,
      });

      onClose?.();
      enqueueSnackbar('Invite members successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Invite Members</DialogTitle>
      <DialogContent>
        <form
          noValidate
          id="group-invite-form"
          onSubmit={handleSubmit(handleInviteMembers)}
          autoComplete="off"
        >
          <Stack spacing={1} minWidth={500}>
            <InputLabel required htmlFor="group-members">
              Members ({group.members?.length ?? 0})
            </InputLabel>
            <AutocompleteElement
              control={control}
              loading={isLoading}
              multiple
              matchId
              id="group-members"
              name="members"
              options={getMembers()}
              required
              autocompleteProps={{
                getOptionLabel: (option) => option.label,
                isOptionEqualToValue: (option, value) => option.id === value.id
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="gray"
          disableElevation
        >
          Back
        </Button>
        <Button
          type="submit"
          form="group-invite-form"
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InviteMemberDialog;
