import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditGroupDialog from './EditGroupDialog';
import useToggleState from '../../../hooks/useToggleState';
import { grey } from '@mui/material/colors';
import InviteMemberDialog from './InviteMembersDialog';
import { useAuth } from '../../../hooks/useAuth';
import { ROLES } from '../../../constants';

function GroupDetailHeader({ group }) {
  const [editGroup, openEditDialog, closeEditDialog] = useToggleState(null);
  const [inviteMemberFlag, openInviteDialog, closeInviteDialog] =
    useToggleState(null);
  const {
    user: { _id, role }
  } = useAuth();

  function handleOpenEditDialog() {
    openEditDialog?.(group);
  }

  function handleOpenInviteDialog() {
    openInviteDialog?.(group);
  }

  if (!group) return null;

  const canEdit = role === ROLES.SUPER_ADMIN || group.managedBy._id === _id;

  return (
    <Card
      variant="outlined"
      sx={{
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: grey[200]
      }}
    >
      <CardContent>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar src={group.cover} />
          <Stack flex={1} justifyContent="center">
            <Typography variant="h6" pb={0}>
              {group.name}
            </Typography>
            {group.description && (
              <Typography variant="body2" color={grey[700]} sx={{ mb: 0.5 }}>
                {group.description}
              </Typography>
            )}
            <Typography variant="caption" color={grey[600]} lineHeight={1}>
              {group.members?.length ?? 0} members
            </Typography>
            <Typography variant="caption" color={grey[800]}>
              {group.online?.length ?? 0} online
            </Typography>
          </Stack>
          <Stack direction="row">
            {canEdit && (
              <IconButton onClick={handleOpenInviteDialog}>
                <PersonAddAlt1Icon />
              </IconButton>
            )}
            {canEdit && <IconButton onClick={handleOpenEditDialog}>
              <MoreVertIcon />
            </IconButton>}
          </Stack>
        </Stack>
      </CardContent>
      {editGroup && (
        <EditGroupDialog
          editable={canEdit}
          group={editGroup}
          open
          onClose={closeEditDialog}
        />
      )}
      <InviteMemberDialog
        group={inviteMemberFlag}
        open={!!inviteMemberFlag}
        onClose={closeInviteDialog}
      />
    </Card>
  );
}

export default GroupDetailHeader;
